import React, { useEffect, useState } from 'react';

function PaymentForm({ onPaymentSuccess }) {
  const [payments, setPayments] = useState(null);
  const [card, setCard] = useState(null);

  useEffect(() => {
    async function loadSquare() {
      if (!window.Square) {
        console.error("Square library not loaded");
        return;
      }

      const payments = window.Square.payments('sandbox-sq0idb-IssrzhBWDTODdmjzDujXrQ', 'sandbox'); // Replace with your Application ID and environment
      const card = await payments.card();
      await card.attach('#card-container');
      setPayments(payments);
      setCard(card);
    }

    loadSquare();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!card) {
      console.error("Card element not initialized");
      return;
    }
    const result = await card.tokenize();
    if (result.status === 'OK') {
      onPaymentSuccess(result.token);
    } else {
      console.error('Tokenization failed:', result.errors);
      alert('Payment failed: ' + result.errors[0].message);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div id="card-container"></div>
      <button type="submit">Pay</button>
    </form>
  );
}

export default PaymentForm;
