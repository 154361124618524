import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import './assets/css/App.css';
import Header from './Header.jsx';
import AboutMe from './AboutMe.jsx';
import Products from './Products.jsx';
import Contact from './Contact.jsx';
import ShoppingCart from './ShoppingCart.jsx';
import Checkout from './Checkout.jsx';
import CartIcon from './CartIcon.jsx';
import OrderComplete from './OrderComplete.jsx';

function App() {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();

  // Function to add products to the cart
  const addToCart = (product, quantity = 1) => {
    setCartItems(prevItems => {
      const existingItem = prevItems.find(item => item.id === product.id);
      if (existingItem) {
        return prevItems.map(item =>
          item.id === product.id ? { ...item, quantity: item.quantity + quantity } : item
        );
      } else {
        return [...prevItems, { ...product, quantity }];
      }
    });
  };

  // Function to update quantity in the cart
  const updateQuantity = (productId, quantity) => {
    setCartItems(prevItems =>
      prevItems.map(item => (item.id === productId ? { ...item, quantity: Math.max(1, quantity) } : item))
    );
  };

  // Function to remove an item from the cart
  const removeItem = productId => {
    setCartItems(prevItems => prevItems.filter(item => item.id !== productId));
  };

  // Function to update custom messages in the cart
  const handleUpdateCustomMessage = (itemId, messageIndex, newMessage) => {
    setCartItems(prevItems =>
      prevItems.map(item =>
        item.id === itemId
          ? {
              ...item,
              customMessages: item.customMessages.map((msg, index) =>
                index === messageIndex ? newMessage : msg
              ),
            }
          : item
      )
    );
  };

  // Function to get total item count in the cart
  const getTotalItemCount = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  // Function to clear the cart after checkout
  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<AboutMe />} />
        <Route path="/about" element={<AboutMe />} />
        <Route path="/products" element={<Products addToCart={addToCart} />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cart" element={
          <ShoppingCart 
            cartItems={cartItems} 
            onUpdateQuantity={updateQuantity} 
            onRemoveItem={removeItem} 
            onUpdateCustomMessage={handleUpdateCustomMessage}  // Pass the custom message update function
            onCheckout={() => navigate('/checkout')}
          />
        } />
        <Route path="/checkout" element={<Checkout cartItems={cartItems} clearCart={clearCart} />} />
        <Route path="/order-complete" element={<OrderComplete />} />
      </Routes>
      <CartIcon itemCount={getTotalItemCount()} onClick={() => navigate('/cart')} />
    </div>
  );
}

export default App;