import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './assets/css/Header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuOpen && !document.querySelector('.submenu').contains(event.target) && !document.querySelector('.burger-menu').contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      window.addEventListener('click', handleOutsideClick);
    } else {
      window.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [menuOpen]);

  return (
    <div className="header">
      <div className="header-title" onClick={() => navigate('/')}>MADE BY JADE</div>
      <div className="burger-menu" onClick={toggleMenu}>
        <div className="burger-bar"></div>
        <div className="burger-bar"></div>
        <div className="burger-bar"></div>
      </div>
      {menuOpen && (
        <div className="submenu-overlay">
          <div className="submenu" onClick={(e) => e.stopPropagation()}>
            <Link to="/" onClick={() => setMenuOpen(false)} className="submenu-item">About Me</Link>
            <Link to="/products" onClick={() => setMenuOpen(false)} className="submenu-item">Products</Link>
            <Link to="/contact" onClick={() => setMenuOpen(false)} className="submenu-item">Contact</Link>
            <Link to="/cart" onClick={() => setMenuOpen(false)} className="submenu-item">Shopping Cart</Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
