import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PaymentForm from './PaymentForm';
import './assets/css/checkout.css';
import axios from 'axios';

function Checkout({ cartItems, clearCart }) {
  const [shippingInfo, setShippingInfo] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: 'US', // Autofill with "US"
    email: '',
  });
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingInfo({ ...shippingInfo, [name]: value });
  };

  const generateOrderNumber = () => {
    return `ORD-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  };

  const testPostRequest = async () => {
    try {
      const response = await axios.post(
        'https://made-by-jade-dd3dd83b001f.herokuapp.com/api/process-payment',
        { test: 'data' },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      console.log('Test POST Response:', response.data);
    } catch (error) {
      console.error('Test POST Error:', error);
    }
  };
  
  testPostRequest();
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowPaymentForm(true);
  };

  return (
    <div className="checkout">
      <h2>Checkout</h2>
      {!showPaymentForm ? (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" value={shippingInfo.name} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={shippingInfo.email} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address:</label>
            <input type="text" id="address" name="address" value={shippingInfo.address} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="city">City:</label>
            <input type="text" id="city" name="city" value={shippingInfo.city} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="state">State:</label>
            <input type="text" id="state" name="state" value={shippingInfo.state} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="zip">ZIP Code:</label>
            <input type="text" id="zip" name="zip" value={shippingInfo.zip} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="country">Country:</label>
            <input type="text" id="country" name="country" value={shippingInfo.country} readOnly /> {/* Autofill and read-only */}
          </div>
          <button type="submit" className="place-order-button">Continue to Payment</button>
        </form>
      ) : (
        <PaymentForm onPaymentSuccess={testPostRequest} />
      )}
    </div>
  );
}

export default Checkout;
