import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/css/App.css';
import App from './App.jsx';

// Create a root container using createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render your app using the root container
root.render(
  <Router>
    <App />
  </Router>
);
