import React from 'react';
import ProductCard from './ProductCard';
import './assets/css/prod.css';

// Import images
import blockLetters from './assets/images/block-letters.jpeg';
import blockStack from './assets/images/block-stack.jpeg';
import blockSide from './assets/images/blocks-side.jpeg';
import bannerTemp from './assets/images/banner-temp.jpeg';
import bannerFinished from './assets/images/banner-finished.jpeg';
import fivePlants from './assets/images/plant-5.jpeg';
import plantModel from './assets/images/plant-model.jpeg';
import plantSpread from './assets/images/plant-spread.jpeg';
import plantTrio from './assets/images/plant-trio.jpeg';
import cupcake from './assets/images/cupcake.jpeg';
import babyTrio from './assets/images/baby-trio.jpeg';
import herbSpread from './assets/images/herb-spread.jpeg';
import strawberry from './assets/images/strawberry.jpeg';
import miniAria from './assets/images/miniAria.jpeg';
import firstDaySpread from './assets/images/firstDaySpread.jpeg';
import signSide from './assets/images/signSide.jpeg';
import blankSide from './assets/images/blankSide.jpeg';
import blankKayla from './assets/images/blankKayla.jpeg';
import coloredKayla from './assets/images/coloredKayla.jpeg';
import coloredSide from './assets/images/coloredSide.jpeg';

function Products({ addToCart }) {
  const products = [
    {
      id: 1,
      name: 'USA blocks',
      description: 'Includes: 3 blocks, laser cut USA and stars, paints, and one paintbrush.',
      price: 12,
      images: [blockLetters, blockStack, blockSide]
    },

    {
      id: 2,
      name: 'USA Banner',
      description: 'Includes: Laser cut flags, USA and stars. includes poly twine, paints, and one paintbrush.',
      price: 15,
      images: [bannerTemp, bannerFinished]
    },

    {
      id: 3,
      name: 'Plant Buddies',
      description: 'Includes: 1 customizable plant buddy. buy 2 get 1 free!',
      priceOptions: [
        { label: '1 Plant Buddy', price: 5 },
        { label: '3 Plant Buddies (Buy 2, Get 1 Free)', price: 10 }
      ],
      images: [plantModel, fivePlants, plantTrio, plantSpread]
    },

    {
      id: 4,
      name: '"Oh Baby" cupcake toppers',
      description: 'Includes: 6 toppers for $12 or 12 for $20.',
      priceOptions: [
        { label: '6 Toppers', price: 12 },
        { label: '12 Toppers', price: 20 }
      ],
      images: [cupcake, babyTrio]
    },

    {
      id: 5,
      name: 'Herb Stakes',
      description: 'Jumbo: 8 inches, 5 for $10 and Mini: 6 inches, 5 for $8.',
      priceOptions: [
        { label: 'Jumbo (5 for $10)', price: 10 },
        { label: 'Mini (5 for $8)', price: 8 }
      ],
      images: [herbSpread, strawberry]
    },

    {
      id: 6,
      name: 'Mini Back to School',
      description: 'Approx. 9.5x6 inches, interchangeable first/last day. Customizable (paint, name, & design).',
      price: 25,
      images: [firstDaySpread, miniAria, signSide]
    },

    {
      id: 7,
      name: 'Regular Back to School',
      description: 'Approx. 8x11 inches, customizable (paint color, name, & design',
      price: 35,
      images: [blankKayla, blankSide, coloredKayla]
    }
  ];

  return (
    <div className='section'>
      <h1>Products</h1>
      <div className='product-grid'>
        {products.map(product => (
          <ProductCard key={product.id} product={product} addToCart={addToCart} />
        ))}
      </div>
    </div>
  );
}

export default Products;