import React from 'react';
import './assets/css/about.css';
import jadePhoto from './assets/images/Jade.jpeg';

function AboutMe() {
  return (
    <div className="section">
      <h1>About Jade</h1>
      <div className="image-container">
       <img src={jadePhoto} alt='Selfie of Owner, Jade'/>
      </div>
      <div className="paragraph-container">
        <p className="windOverlay">
          Hello!<br />
          Welcome to Made By Jade.<hr />
          My name is Jade, the maker behind the scenes. I’ve always loved creating, so naturally, when I had an opportunity to start my small business I took it!<br />
          I own a Glowforge Plus and it’s been so fun creating crafts, gifts, decor, and more! I have also learned how to create my own SVG files, so watch for those!
          Along with creating, I love to make people smile. So if you have an idea of what you're looking for and don’t see it, email me and let’s work together to make what you’re envisioning come to life!
          Let’s get crafting!<br />
          With love,
          Jade
        </p>
      </div>
    </div>
  );
}

export default AboutMe;