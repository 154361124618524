import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import './assets/css/CartIcon.css';

function CartIcon({ itemCount }) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (itemCount > 0) {
      setAnimate(true);
      const timer = setTimeout(() => setAnimate(false), 500);
      return () => clearTimeout(timer);
    }
  }, [itemCount]);

  return (
    <Link to="/cart" className={`cart-icon ${animate ? 'animate' : ''}`}>
      <FontAwesomeIcon icon={faShoppingCart} />
      {itemCount > 0 && <span className="cart-count">{itemCount}</span>}
    </Link>
  );
}

export default CartIcon;

