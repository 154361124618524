import React from 'react';
import { useLocation } from 'react-router-dom';
import './assets/css/order-complete.css';

function OrderComplete() {
  const location = useLocation();
  const { orderNumber } = location.state || {};

  return (
    <div className="order-complete">
      <h2>Order Complete</h2>
      <p>Thank you for your purchase! Your order has been successfully processed.</p>
      {orderNumber && (
        <div className="order-number-container">
          <p>Your Order Number:</p>
          <strong className="order-number">{orderNumber}</strong>
        </div>
      )}
    </div>
  );
}

export default OrderComplete;
