import React, { useState, useRef, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './assets/css/prod.css';

function ProductCard({ product, addToCart }) {
  const [selectedPrice, setSelectedPrice] = useState(product.priceOptions ? product.priceOptions[0].price : product.price);
  const [customMessages, setCustomMessages] = useState(['']);
  const messageRefs = useRef([]);

  const handlePriceChange = (e) => {
    const selectedOption = product.priceOptions.find(option => option.label === e.target.value);
    setSelectedPrice(selectedOption.price);
  };

  const handleMessageChange = (index, value) => {
    const updatedMessages = [...customMessages];
    updatedMessages[index] = value;
    setCustomMessages(updatedMessages);
  };

  const addNewMessageField = () => {
    setCustomMessages([...customMessages, '']);
  };

  const handleAddToCart = () => {
    // Capture the latest values from the textareas before adding to cart
    const latestMessages = messageRefs.current.map(ref => ref.value);
    setCustomMessages(latestMessages);
    addToCart({ ...product, price: selectedPrice, customMessages: latestMessages });
  };

  useEffect(() => {
    customMessages.forEach((_, index) => {
      if (messageRefs.current[index]) {
        messageRefs.current[index].style.height = 'auto';
        messageRefs.current[index].style.height = `${messageRefs.current[index].scrollHeight}px`;
      }
    });
  }, [customMessages]);

  return (
    <div className='product-card'>
      <Carousel showThumbs={false}>
        {product.images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`${product.name} ${index + 1}`} className='product-image'/>
          </div>
        ))}
      </Carousel>
      <div className='product-info'>
        <p>{product.description}</p>
        {product.priceOptions && (
          <select onChange={handlePriceChange}>
            {product.priceOptions.map((option, index) => (
              <option key={index} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>
        )}
        {(product.id === 3 || product.id === 5 || product.id === 6 || product.id === 7) && (
          <>
            {customMessages.map((message, index) => (
              <textarea
                key={index}
                ref={el => (messageRefs.current[index] = el)}
                value={message}
                onChange={(e) => handleMessageChange(index, e.target.value)}
                placeholder={
                  product.id === 5 ? "Enter your plant name and number of each" : "Enter your custom message"
                }
                className="custom-message"
              />
            ))}
            <button onClick={addNewMessageField} className="add-message-button">Add Another Message</button>
          </>
        )}
      </div>
      <div className="product-footer">
        <span className="product-price">${selectedPrice}</span>
        <button onClick={handleAddToCart} className="add-to-cart-button">Add to Cart</button>
      </div>
    </div>
  );
}

export default ProductCard;





