import React from 'react';
import './assets/css/cart.css';

function ShoppingCart({ cartItems, onUpdateQuantity, onRemoveItem, onUpdateCustomMessage, onCheckout }) {
  const handleCustomMessageChange = (itemId, messageIndex, newMessage) => {
    onUpdateCustomMessage(itemId, messageIndex, newMessage);
  };

  return (
    <div className="shopping-cart">
      <h2>Shopping Cart</h2>
      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <>
          <ul>
            {cartItems.map(item => (
              <li key={item.id} className="cart-item">
                <img src={item.images[0]} alt={item.name} className="cart-item-image" />
                <div className="cart-item-details">
                  <p>{item.name}</p>
                  <p>${item.price.toFixed(2)}</p>
                  <div className="quantity-control">
                    <button onClick={() => onUpdateQuantity(item.id, item.quantity - 1)}>-</button>
                    <input type="number" value={item.quantity} readOnly />
                    <button onClick={() => onUpdateQuantity(item.id, item.quantity + 1)}>+</button>
                  </div>
                  {/* Editable Custom Messages */}
                  {item.customMessages && item.customMessages.length > 0 && (
                    <div className="custom-messages">
                      <h4>Custom Messages:</h4>
                      {item.customMessages.map((message, index) => (
                        <textarea
                          key={index}
                          value={message}
                          onChange={(e) => handleCustomMessageChange(item.id, index, e.target.value)}
                          className="custom-message"
                        />
                      ))}
                    </div>
                  )}
                  <button onClick={() => onRemoveItem(item.id)}>Remove</button>
                </div>
              </li>
            ))}
          </ul>
          <div className="cart-total">
            Total: ${cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}
          </div>
          <button onClick={onCheckout} className="checkout-button">Checkout</button>
        </>
      )}
    </div>
  );
}

export default ShoppingCart;




